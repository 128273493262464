
import { Component, Prop, Vue } from "vue-property-decorator";
import { Location } from "vue-router";

@Component
export default class FormSet extends Vue {
  @Prop({ type: String })
  readonly title!: string;

  @Prop({ type: Boolean, default: false })
  readonly isBlock?: boolean;

  @Prop({ type: Boolean })
  readonly loading!: boolean;

  @Prop({ type: String })
  readonly href?: string;

  @Prop({ type: String })
  readonly buttonStyle!: "course" | "focus" | "work" | "cancel" | "ok" | "unfocus" | "delete" | "course-ok" | "default";

  @Prop({ type: String })
  readonly width?: string;

  @Prop({ type: String })
  readonly height?: string;

  @Prop({ type: Boolean })
  readonly outlined?: boolean;

  @Prop({ type: Location })
  readonly to?: Location;
  @Prop({ type: Boolean })
  readonly disabled?: boolean;
}
