
import { Component, Vue } from "vue-property-decorator";
import Cognito from "@/plugins/cognito";
import AuthModule from "@/store/auth";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";

@Component({
  components: {
    Button,
    FormSet,
  },
})
export default class LoginForm extends Vue {
  private showPass = false;

  private showLabel = false;

  private loginSave = false;

  private form = {
    email: "",
    password: "",
  };

  private passwordToggle() {
    this.showPass = !this.showPass;
  }

  private loginError() {
    this.showLabel = true;
    setTimeout(() => {
      this.showLabel = false;
    }, 3000);
  }
  private async submit() {
    try {
      const operator = await Cognito.signIn(this.form.email, this.form.password);
      if (operator) {
        AuthModule.changeMe(operator);
        this.$router.push({ name: "HospitalSelect" });
      }
    } catch (error) {
      this.loginError();
    }
  }
}
